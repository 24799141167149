import React from 'react';
import 'src/styles/main.scss';
import { IntlProvider } from 'react-intl';
import getLocaleDataFromString from 'src/helpers/stringManipulation';
import languageData from 'src/languages';

interface Props {
	location: { pathname: string };
}

const Layout: React.FC<Props> = ({ children, location }) => {
	const { pathname } = location;
	const localeData = getLocaleDataFromString(pathname);

	return (
		<IntlProvider
			messages={localeData.messages}
			locale={localeData.locale}
			defaultLocale={languageData.Default.locale}
		>
			{children}
		</IntlProvider>
	);
};

export default Layout;
