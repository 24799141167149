import en from './en.json';

export interface Languages<T> {
	[key: string]: T | undefined;
	Default: T; // Default language is not optional

	// Add more optional languages to the list if you need to
}

export interface LocaleData {
	locale: string;
	title: string;
	messages: Record<string, string>;
}

const languageData: Languages<LocaleData> = {
	Default: { locale: 'en', title: 'English', messages: en },

	// Add more language data to the list if you need to
};

export default languageData;
